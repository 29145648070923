@use "sass:map";
//body { margin: 0; font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important; }

.sticky-top {
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.horizontal-divider {
  width: 2px;
  height: 2.2rem;
  background: var(--assessment-grey-ninty);
}

.divider-horizontal-line {
  height: 2px;
  background-color: var(--assessment-grey-four);
}

.header-primary {
  //height: 6%;
  background: var(--assessment-white);
  color: var(--assessment-black);
  border-bottom: 1px solid var(--assessment-grey-four);

  .logo {
    color: var(--assessment-palette-sixtyseven);
  }

  .mat-tab-group .mat-tab-label-active {
    color: var(--assessment-palette-sixtyseven);
  }

  .mat-tab-group .mat-tab-label:not(.mat-tab-label-active) {
    color: var(--assessment-palette-seventytwo) !important;
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    background: var(--assessment-palette-sixtyseven);
  }
}

.header-secondary {
  //height: 30%;
  background: linear-gradient(180deg, #5c87e4 100%, #2a50a4 0%);
  color: var(--assessment-white);

  .logo {
    color: var(--assessment-white);
  }

  .mat-tab-group .mat-tab-label {
    color: var(--assessment-white);
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    background: var(--assessment-white);
  }

  .title {
    font-weight: 700;
    font-size: 1.5rem;
  }
  .sub-title {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1rem;
    letter-spacing: -0.01em;
    opacity: 0.65;
    text-align: center;
  }
}

.header-default {
  .sign-in {
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
  }
}

.input-docoration-underline {
  text-decoration: underline;
}

.text-highlighter:hover {
  color: var(--assessment-palette-sixtyfive) !important;
  font-weight: bold;
}

.header {
  .mat-tab-group .mat-tab-label {
    opacity: 1;
    font: normal normal 400 1rem/1.2rem assessment-Regular;
  }

  .mat-tab-group .mat-tab-label-active {
    font: normal normal 700 1rem/1.2rem assessment-Regular;
  }

  .mat-tab-group .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    box-shadow: 0px 0px 40px rgba(253, 230, 63, 0.24);
    border-radius: 20px;
    height: 4px;
  }
}

.input-box {
  border: 1px solid var(--assessment-grey-four) !important;
  border-radius: 8px;
  outline: none;
  letter-spacing: 0px;
  color: var(--assessment-grey-ten);
  padding: 0.5rem 1rem;
  font: normal normal normal 0.85rem/1.7rem assessment-Regular;
  background: var(--assessment-white) 0% 0% no-repeat padding-box;
  height: 3.5rem;
}

.select-btn {
  border: 1px solid var(--assessment-white);
  background: var(--assessment-white);
  height: 2rem;
  outline: none;
}

.w-40 {
  width: 40%;
}
.wh-1 {
  width: 1.2rem;
  height: 1.2rem;
}
.wh-2 {
  width: 2rem;
  height: 2rem;
}
.assessment-button {
  //background: var(--assessment-palette-sixtyfive) 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border: none;
}
.question-bank-new {
  background: var(--assessment-white) 0% 0% no-repeat padding-box;
  border: 0.5px solid var(--assessment-grey-four);
  border-radius: 4px;
  opacity: 1;
}
.searchinput {
  border: 1px solid var(--assessment-grey-four);
}
.cursor-pointer {
  cursor: pointer;
  .backgroud-form {
    background: var(--assessment-grey-one);
  }
}
.box-layout {
  border: 1px solid #63666a;
  background: var(--assessment-white) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px var(--assessment-palette-seventeen);
  border-radius: 4px;
}

.form-text-light {
  font-family: assessment-Medium;
  font-size: 0.8rem;
  color: #222222;
  opacity: 0.7;
}
.form-title {
  font-family: assessment-Medium;
  font-size: 0.75rem;
  letter-spacing: 0.7px;
  color: var(--assessment-text-color);
  text-transform: uppercase;
  opacity: 0.6;
}
.thumbnail-medium {
  width: 10rem;
  height: 10rem;
}

.object-fit-cover {
  object-fit: cover;
}

.zero-padding-container {
  width: 35%;
  .mat-dialog-container {
    padding: 0 !important;
    border-radius: 1rem;
    overflow: hidden;
  }
  @media only screen and (max-width: 991.5px) {
    width: 100%;
  }
}

@media only screen and (max-width: 991.5px) {
  .max-width-container {
    .cdk-overlay-pane {
      width: 100% !important;
    }
  }
}

.custom-bg-class {
  color: var(--app-theme-based-color);
}
.custom-bg-class-one {
  color: var(--app-theme-based-one);
}

//buttons

.zero-btn-properties {
  background: var(--assessment-white);
  outline: none;
  border: none;
  padding: 0;
}

.btn-outline-solid {
  background: var(--assessment-white) 0% 0% no-repeat padding-box;
  border: 0.1px solid var(--assessment-grey-nine);
  border-radius: 4px;
  align-items: center;
  letter-spacing: 0px;
  color: var(--assessment-grey-nine);
  padding: 0.8rem;
}

.btn-outline-shade-0 {
  background: var(--assessment-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--assessment-grey-four);
  border-radius: 4px;
  outline: none;
  font-family: assessment-Medium;
  font-size: 0.8rem;
  color: var(--assessment-grey-eight);
}

.btn-outline-shade-1 {
  background: var(--assessment-white) 0% 0% no-repeat padding-box;
  border: 0.1px solid var(--assessment-palette-eightyone);
  border-radius: 0.2rem;
  align-items: center;
  letter-spacing: 0px;
  color: var(--assessment-palette-eightyone);
  padding: 0.8rem;
  font-family: assessment-Bold;
  font-size: 0.8rem;
}
.btn-outline-shade-2 {
  border-radius: 0.2rem;
  font-family: assessment-Medium;
  font-size: 1rem;
  letter-spacing: 0px;
  color: var(--assessment-palette-sixtyfive);
  outline: none;
  padding: 0.5rem;
  border: none;
  min-width: 3.5rem;
}
.btn-outline-shade-3 {
  background: rgba(231, 100, 111, 0.1) 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 0.5rem;
  letter-spacing: 0px;
  color: var(--assessment-palette-fourtytwo);
  font-family: assessment-Medium;
  font-size: 0.8rem;
  padding: 0.5rem;
}
.btn-outline-shade-4 {
  background: var(--assessment-white);
  border-radius: 0.2rem;
  font-family: assessment-Bold;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-palette-sixtyfive);
  outline: none;
  padding: 0.5rem;
  border: none;
}

.btn-shade-1 {
  background: rgba(99, 102, 106, 0.1) 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  font-family: assessment-Medium;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-palette-sixtyfive);
  outline: none;
  padding: 0.5rem;
  border: none;
}
.btn-shade-2 {
  background: var(--assessment-palette-sixtyfive);
  border-radius: 0.25rem;
  font-family: assessment-medium;
  font-size: 1rem;
  letter-spacing: 0px;
  color: var(--assessment-white);
  outline: none;
  padding: 0.5rem;
  border: none;
  min-width: 9.5rem;
}
.btn-shade-3 {
  background: var(--assessment-palette-eightyone);
  border-radius: 0.2rem;
  font-family: assessment-Bold;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-white);
  outline: none;
  padding: 0.8rem;
  border: none;
}
.btn-shade-4 {
  background: var(--assessment-palette-eighty);
  border-radius: 0.2rem;
  font-family: assessment-Bold;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-white);
  outline: none;
  padding: 0.8rem;
  border: none;
}
.btn-shade-5 {
  background: rgba(237, 111, 102, 0.1);
  border-radius: 0.2rem;
  font-family: assessment-Medium;
  font-size: 0.8rem;
  color: var(--assessment-palette-fortytwo);
  outline: none;
  border: none;
}
.btn-shade-6 {
  background: rgba(128, 134, 139, 0.1);
  border-radius: 0.2rem;
  font-family: assessment-Medium;
  font-size: 0.8rem;
  color: var(--assessment-grey-seven);
  outline: none;
  border: none;
}
.btn-shade-7 {
  background: var(--assessment-grey-four);
  border-radius: 0.2rem;
  font-family: assessment-Bold;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-grey-eight);
  outline: none;
  padding: 0.5rem;
  border: none;
}
.btn-shade-8 {
  background: var(--assessment-palette-eightytwo);
  border-radius: 0.2rem;
  font-family: assessment-Medium;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-grey-ten);
  outline: none;
  padding: 0.5rem;
  border: none;
}
.btn-shade-9 {
  background: var(--assessment-palette-fortytwo);
  border-radius: 0.2rem;
  font-family: assessment-Medium;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-grey-ten);
  outline: none;
  padding: 0.5rem;
  border: none;
}
.btn-shade-10 {
  background: var(--assessment-grey-ten);
  border-radius: 0.2rem;
  font-family: assessment-Medium;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-white);
  outline: none;
  padding: 0.5rem;
  border: none;
}
.btn-shade-11 {
  background: var(--assessment-primary-one);
  border-radius: 0.2rem;
  font-family: assessment-Bold;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-white);
  outline: none;
  padding: 0.5rem;
  border: none;
}
.btn-shade-12 {
  background: var(--assessment-palette-eightytwo);
  border-radius: 0.2rem;
  font-family: assessment-Medium;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-white) !important;
  outline: none;
  padding: 0.5rem;
  border: none;
}
.btn-shade-13 {
  background: var(--assessment-palette-eightythree);
  border-radius: 0.2rem;
  font-family: assessment-Bold;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--app-theme-based-color);
  outline: none;
  padding: 0.5rem;
  border: none;
}
.btn-shade-14 {
  background: var(--assessment-grey-two);
  border-radius: 0.2rem;
  font-family: assessment-Bold;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-grey-nine);
  outline: none;
  padding: 0.5rem;
  border: none;
}
.btn-shade-15 {
  background: var(--assessment-palette-onehundredtwenty);
  border-radius: 0.2rem;
  font-family: assessment-Medium;
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: var(--assessment-black);
  outline: none;
  padding: 0.5rem;
  border: none;
}

.btn-shade-16 {
  font-family: assessment-Medium;
  font-size: 0.8rem;
  color: var(--assessment-palette-sixtyfive);
  outline: none;
  border-radius: 0.25rem;
  border: 2px solid var(--assessment-palette-sixtyfive);
}

.btn-shade-17 {
  font-family: assessment-Medium;
  font-size: 0.8rem;
  color: var(--assessment-palette-sixtyfive);
  outline: none;
  border-radius: 0.25rem;
  border: none;
  background: #f0f0f0;
}
.btn-shade-18 {
  font-family: assessment-Medium;
  font-size: 0.8rem;
  color: var(--assessment-palette-sixtyfive);
  outline: none;
  border-radius: 0.25rem;
  border: 2px solid var(--assessment-palette-sixtyfive);
  background: var(--assessment-white);
}

.text-shade-1 {
  color: var(--assessment-palette-sixtyfive);
  font-family: assessment-Bold;
  font-size: 1rem;
}
.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
}

.opacity-1 {
  opacity: 0.1;
}
.opacity-2 {
  opacity: 0.2;
}
.opacity-3 {
  opacity: 0.3;
}
.opacity-4 {
  opacity: 0.4;
}
.opacity-5 {
  opacity: 0.5;
}
.opacity-6 {
  opacity: 0.6;
}
.opacity-7 {
  opacity: 0.7;
}
.opacity-8 {
  opacity: 0.8;
}
.opacity-9 {
  opacity: 0.9;
}

.margin-auto {
  margin: auto;
}

.w-15 {
  width: 15rem;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
  background: var(--assessment-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--assessment-grey-four);
  border-radius: 8px;
  outline: none;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background: var(--assessment-white);
  border-bottom: 0.5px solid var(--assessment-grey-four);
  outline: none;
  padding: 0em 0.75em 0 0 !important;
}
.form-input-full {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    outline: none;
    padding: 0em 0.75em 0 1em !important;
    background: var(--assessment-white) 0% 0% no-repeat padding-box;
    border: 1px solid var(--assessment-grey-four);
    border-radius: 0.5rem;
  }
}
.custom-arrow {
  .mat-select .mat-select-trigger {
    display: flex;
    align-items: baseline;
  }
}

.toggle-btn {
  .form-switch .form-check-input {
    width: 2.2em;
    height: 1.2em;
  }
  .form-check-input:checked {
    background-color: var(--assessment-palette-sixtyfive) !important;
  }
}

.no-lib-drop-arrow {
  .mat-select-arrow {
    display: none;
  }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgb(116 118 237 / 30%);
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--assessment-palette-sixtyfive);
}

//filter
.filter-purple {
  filter: var(--filter-purple);
}
.filter-gray {
  filter: invert(20%) sepia(15%) saturate(242%) hue-rotate(163deg)
    brightness(97%) contrast(85%);
}
.filter-white {
  filter: invert(0%) sepia(100%) saturate(33%) hue-rotate(88deg)
    brightness(161%) contrast(128%);
}
.filter-black {
  filter: invert(0%) sepia(4%) saturate(0%) hue-rotate(316deg) brightness(102%)
    contrast(104%);
}

.mat-form-field-appearance-fill .mat-form-field-flex .mat-input-element {
  letter-spacing: 0px;
  color: var(--assessment-grey-ten);
  font-size: 1rem;
  font-family: assessment-Regular;
}
.mat-form-field .mat-form-field-underline {
  display: none;
  //background-color: transparent;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-underline {
  display: block;
}
.custom-mat-form {
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding: 0 !important;
  }
  .mat-form-field-infix {
    border: 0;
  }
  .mat-date-range-input-mirror {
    visibility: visible;
  }
}

.custom-select-view {
  .mat-select-value-text {
    font-family: assessment-Regular;
    font-weight: 400;
    font-size: 0.8rem;
    color: var(--assessment-text-grey-nine);
  }
}
.right-0 {
  right: 0;
}

.camel-case {
  text-transform: capitalize;
}
.border-radius-circle {
  border-radius: 50%;
}
.lottie-loader {
  height: 15rem;
  width: 15rem;
}
.full-view-height {
  height: 100vh;
}
.text-minimize {
  white-space: initial;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.url-highlighter-1:hover {
  color: var(--assessment-palette-sixtyfive) !important;
  font-weight: bold;
}
.scrollable {
  overflow-y: scroll;
}
.scrollable::-webkit-scrollbar {
  width: 5px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: var(--assessment-grey-six);
  border-radius: 10px;
}

.nav-hyperlink {
  a {
    text-decoration: none;
    color: inherit;
  }
}

//maker app-need to remove
.btn {
  border: none;
  background-color: inherit;
  padding: 10px 28px;
  cursor: pointer;
  display: inline-block;
}

/* Green */
.success {
  color: var(--assessment-success);
}

.success:hover {
  background-color: var(--assessment-success) !important;
  color: var(--assessment-white) !important;
}

/* Orange */
.warning {
  color: orange;
}

.warning:hover {
  background: #ff9800 !important;
  color: var(--assessment-white) !important;
}

/* Red */
.danger {
  color: #ff3e58;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #ff3e58;
  border-radius: 4px;
  opacity: 1;
}

.danger:hover {
  background: #f44336 !important;
  color: var(--assessment-white) !important;
}

.login-dialog mat-dialog-container {
  background-color: var(--assessment-grey-one) !important;
}

// Commons Buttons

.success-snackbar {
  background: #00a80e;
  color: var(--assessment-white) !important;

  .mat-simple-snackbar {
    color: var(--assessment-white) !important;
  }
  .mat-simple-snackbar-action {
    color: var(--assessment-white) !important;
  }
}
.blue-snackbar {
  background: #5d5d5d;
  color: var(--assessment-white) !important;

  .mat-simple-snackbar {
    color: var(--assessment-white) !important;
  }
  .mat-simple-snackbar-action {
    color: var(--assessment-white) !important;
  }
}

::ng-deep .mat-simple-snackbar {
  color: var(--assessment-white) !important;
}

.mat-bg-black {
  .mat-select-panel-wrap {
    .mat-select-panel {
      background-color: var(--assessment-black) !important;
    }
  }

  .mat-form-field-flex {
    background-color: var(--assessment-black) !important;
  }

  .mat-select-value {
    color: var(--assessment-white) !important;
  }
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--assessment-palette-sixtyfive) !important;
}
.custom-dialog-container .mat-dialog-container {
  padding: 0;
}

textarea:focus,
input:focus {
  outline: none;
}
.paginator {
  .paginator-pages {
    max-width: 13.438rem;

    .paginator-wrap {
      transform: translateX(0);
      transition-duration: 0.5s;

      li {
        button {
          background-color: transparent;
          width: 2.188rem;
          height: 2.188rem;
          transition-duration: 0.5s;

          &.active {
            background-color: var(--assessment-primary-one);
          }
        }
      }
    }
  }

  .button {
    width: 0.438rem;
    height: 0.438rem;
    line-height: 0;
    background-color: transparent;

    &.right {
      img {
        transform: rotate(180deg);
        transform-origin: center;
      }
    }

    &:disabled {
      opacity: 0.3;
    }
  }
}

p {
  font-weight: normal;
}

// small {
//   font-size: 85% !important;
// }

.mat-radio-outer-circle {
  height: 1.12rem !important;
  left: 0.07rem !important;
  top: 0.08rem !important;
  width: 1.12rem !important;
  border-width: 1px !important;
  border-color: hsl(214deg 18% 50% / 70%) !important;
}
.mat-radio-label-content {
  padding-left: 0.8rem !important;
}
.mat-checkbox-frame {
  border-width: 0.1rem !important;
  border-color: var(--assessment-palette-eightyfour) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #424857;
}
.dpickerbtn .mat-button-ripple.mat-ripple,
.mat-button-focus-overlay {
  width: 1.6rem !important;
  margin-top: 0.3rem !important;
  height: 1.8rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: auto !important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #b5b5b5 !important;
}
.mat-form-field-label-wrapper {
  top: -1.2rem;
  /* padding-top: .84375em; */
}
input.mat-input-element {
  // margin-left: 6px;
  position: relative;
  padding-left: 4px;
  width: 98%;
  top: -0.35rem;
}

.custom-trigger {
  .mat-select-trigger {
    width: 75% !important;
  }
}

.mat-padding-0 {
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
  }
}

.mat-width-80 {
  .mat-select-value {
    width: 81%;
  }
}

::-webkit-scrollbar {
  width: 10px;
  background: #eaebec;
}

::-webkit-scrollbar-thumb {
  background-color: #9aa0a6;
  //border-radius: 10px;
}

//taker app css

.response-interface {
  // colors

  .mat-radio-outer-circle {
    height: 1.12rem !important;
    left: 0.07rem !important;
    top: 0.08rem !important;
    width: 1.12rem !important;
    border-width: 1px !important;
    border-color: hsl(214deg 18% 50% / 70%) !important;
  }
  .mat-radio-label-content {
    padding-left: 0.8rem !important;
  }
  .mat-checkbox-frame {
    border-width: 0.1rem !important;
    border-color: var(--assessment-palette-eightyfour) !important;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: #424857;
  }
  .dpickerbtn .mat-button-ripple.mat-ripple,
  .mat-button-focus-overlay {
    width: 1.6rem !important;
    margin-top: 0.3rem !important;
    height: 1.8rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: auto !important;
  }
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #b5b5b5 !important;
  }
  .mat-form-field-label-wrapper {
    top: -1.2rem;
    /* padding-top: .84375em; */
  }
  input.mat-input-element {
    // margin-left: 6px;
    position: relative;
    width: 98%;
    top: -11px;
  }

  //********** Style.scss Ends ************

  //********** Survey Component.scss starts ************

  // textarea.mat-input-element {
  //   padding: 0rem 0.9rem 0rem 1rem !important;
  //   margin: -0.8rem 0rem -0.3rem -0.3rem !important;
  //   width: 97%;
  // }

  .mat-progress-bar {
    transition: opacity 250ms linear;
    margin: 25% auto;
    width: 75%;
  }

  .mat-raised-button.mat-button-disabled {
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 6px !important;
  }
  .mat-raised-button {
    border-radius: 6px !important;
  }

  // .content.mat-form-field.ng-invalid.ng-touched .mat-form-field-flex {
  //   border: 0.063rem solid map.get($theme-colors, "danger") !important;
  //   border-radius: 0.25rem;
  //   padding-left: 0.08rem;
  //   padding-top: 0.01rem;
  //   margin-left: -0.02rem;
  // }
  // .content.mat-form-field .mat-form-field-flex {
  //   border: 0.063rem solid #b5b5b5;
  //   border-radius: 0.25rem;
  //   padding-left: 0.08rem;
  //   padding-top: 0.01rem;
  //   margin-left: -0.02rem;
  // }

  // radio button color settings

  .nps-form-radio-group {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    flex-direction: column;
    .nps-option-unlikely-rating {
      position: relative;
      display: flex;
      align-self: flex-start;
      padding-left: 0.125rem;
    }
    .nps-options {
      // justify-content: space-between;
      display: flex;
      .nps-option {
        margin-right: 0.625rem;
        width: 2.5rem;
        margin-top: 0.313rem;
        margin-bottom: 0.313rem;
        .mat-radio-container .mat-radio-outer-circle {
          width: 2.5rem !important;
          height: 2.5rem !important;
          border-radius: 0.25rem;
          border-color: #b5b5b5 !important;
          left: 0rem !important;
          top: 0rem !important;
        }
        .mat-radio-container {
          width: 2.5rem;
          height: 2.5rem;
        }

        .mat-radio-inner-circle {
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 4px;
          left: 0rem;
          top: 0rem;
        }
        .mat-radio-label {
          margin-bottom: 0rem !important;
        }
        .mat-radio-label-content {
          margin-left: -100%;
          position: relative;
          padding-left: 0rem !important;
          width: 2.5rem;
          text-align: center;
        }
      }

      .nps-option.mat-radio-checked .mat-radio-inner-circle {
        transform: scale(1) !important;
      }
    }
    .nps-option-likely-rating {
      position: relative;
      margin-left: 0%;
      text-align: end;
    }
  }

  .mat-raised-button.mat-button-disabled {
    pointer-events: none;
  }

  .mat-select-arrow {
    opacity: 0;
  }
  .mat-select-arrow-wrapper {
    background-repeat: no-repeat;
    display: none;
  }

  .object-fit-cover {
    object-fit: cover;
  }
}
