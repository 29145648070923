@use "sass:math";

.character-spacing-1-2 {
  letter-spacing: 1.2px;
}

.opacity-0-7 {
  opacity: 0.7;
}

/*
    assessment-text-color
*/
.assessment-text-white {
  color: var(--assessment-white) !important;
}
.assessment-text-black {
  color: var(--assessment-black);
}
.assessment-text-color {
  color: var(--assessment-text-color) !important;
}
.assessment-text-secondary-color {
  color: var(--assessment-text-secondary-color);
}
.assessment-text-success {
  color: var(--assessment-success);
}
.assessment-text-success-secondary {
  color: var(--assessment-success-secondary);
}
.assessment-text-danger {
  color: var(--assessment-danger) !important;
}
.assessment-text-warning {
  color: var(--assessment-warning);
}
.assessment-text-warm {
  color: var(--assessment-warm);
}
.assessment-text-primary-light {
  color: var(--assessment-primary-light);
}
.assessment-text-primary-one {
  color: var(--assessment-primary-one) !important;
}
.assessment-text-primary-two {
  color: var(--assessment-primary-two);
}
.assessment-text-primary-three {
  color: var(--assessment-primary-three);
}
.assessment-text-grey-one {
  color: var(--assessment-grey-one);
}
.assessment-text-grey-two {
  color: var(--assessment-grey-two);
}
.assessment-text-grey-three {
  color: var(--assessment-grey-three);
}
.assessment-text-grey-four {
  color: var(--assessment-grey-four);
}
.assessment-text-grey-five {
  color: var(--assessment-grey-five);
}
.assessment-text-grey-six {
  color: var(--assessment-grey-six);
}
.assessment-text-grey-seven {
  color: var(--assessment-grey-seven) !important;
}
.assessment-text-grey-eight {
  color: var(--assessment-grey-eight) !important;
}
.assessment-text-grey-nine {
  color: var(--assessment-grey-nine) !important;
}
.assessment-text-golden {
  color: var(--assessment-golden) !important;
}
.assessment-text-grey-ten {
  color: var(--assessment-grey-ten);
}
.assessment-text-grey-eleven {
  color: var(--assessment-grey-eleven);
}
.assessment-text-grey-thirteen {
  color: var(--assessment-grey-thirteen);
}
.assessment-text-grey-fourteen {
  color: var(--assessment-grey-fourteen);
}
.assessment-text-grey-seventeen {
  color: var(--assessment-grey-seventeen);
}
.assessment-grey-ten {
  color: var(--assessment-grey-ten);
}
.assessment-text-grey-eighteen {
  color: var(--assessment-palette-onehundredtwentyfive);
}
.assessment-text-palette-one {
  color: var(--assessment-palette-one);
}
.assessment-text-palette-four {
  color: var(--assessment-palette-four);
}
.assessment-text-palette-ten {
  color: var(--assessment-palette-ten);
}
.assessment-text-palette-eleven {
  color: var(--assessment-palette-eleven);
}
.assessment-text-palette-thirtynine {
  color: var(--assessment-palette-thirtynine);
}
.assessment-text-palette-fiftyfour {
  color: var(--assessment-palette-fiftyfour);
}
.assessment-text-palette-forty {
  color: var(--assessment-palette-forty) !important;
}
.assessment-text-palette-fortyone {
  color: var(--assessment-palette-fortyone);
}
.assessment-text-palette-fortytwo {
  color: var(--assessment-palette-fortytwo) !important;
}
.assessment-text-palette-fortyfour {
  color: var(--assessment-palette-fortyfour);
}
.assessment-text-extra-light {
  font-family: assessment-ExtraLight;
}
.assessment-text-palette-thirtythree {
  color: var(--assessment-palette-thirtythree);
}
.assessment-text-palette-thirtynine {
  color: var(--assessment-palette-thirtynine);
}
.assessment-text-palette-fiftysix {
  color: var(--assessment-palette-fiftysix);
}
.assessment-text-palette-seventynine {
  color: var(--assessment-palette-seventynine);
}
.assessment-text-palette-sixtyfive {
  color: var(--assessment-palette-sixtyfive);
}
.assessment-text-palette-eighty {
  color: var(--assessment-palette-eighty);
}
.assessment-text-palette-eightyone {
  color: var(--assessment-palette-onehundredtwentysix);
}
.assessment-text-palette-eightytwo {
  color: var(--assessment-palette-onehundredtwentyfive);
}
.assessment-header-background {
  background-color: var(--assessment-white);
  box-shadow: var(--assessment-box-shadow-seven);
}

.assessment-text-light-47 {
  font-family: assessment-Light;
  font-size: calc(2.5rem + 0.4vw);
  line-height: calc(3rem + 0.2vw);
}

// 13px ? 12px
.assessment-text-regular-12 {
  font-family: assessment-Regular;
  font-size: calc(0.5rem + 0.3vw);
  line-height: calc(0.9rem + 0.3vw);
}
.assessment-text-bold-12 {
  font-family: assessment-Bold;
  font-size: calc(0.5rem + 0.3vw);
  line-height: calc(0.9rem + 0.3vw);
}
.assessment-text-semibold-12 {
  font-family: assessment-SemiBold;
  font-size: calc(0.5rem + 0.3vw);
  line-height: calc(0.9rem + 0.3vw);
}
.assessment-text-semibold-50 {
  font-family: assessment-SemiBold;
  font-size: 3.125rem;
}
.assessment-text-light-12 {
  font-family: assessment-Light;
  font-size: calc(0.5rem + 0.3vw);
  line-height: calc(0.9rem + 0.3vw);
}
.assessment-text-medium-10 {
  font-family: assessment-Medium;
  font-size: calc(0.5rem + 0.1vw);
  line-height: calc(0.9rem + 0.1vw);
}
.assessment-text-medium-11 {
  font-family: assessment-Medium;
  font-size: calc(0.4rem + 0.1vw);
  line-height: calc(0.9rem + 0.1vw);
}
.assessment-text-medium-12 {
  font-family: assessment-Medium;
  font-size: calc(0.5rem + 0.3vw);
  line-height: calc(0.9rem + 0.3vw);
}
.assessment-text-medium-upper-12 {
  font-family: assessment-Medium;
  font-size: calc(0.5rem + 0.3vw);
  line-height: calc(0.9rem + 0.3vw);
  text-transform: uppercase;
}

.assessment-text-regular-11 {
  font-family: assessment-Regular;
  font-size: calc(0.5rem + 0.2vw) !important;
  line-height: calc(0.9rem + 0.2vw) !important;
}
.assessment-text-regular-13 {
  font-family: assessment-Regular !important;
  font-size: calc(0.65rem + 0.2vw) !important;
  line-height: calc(0.9rem + 0.2vw) !important;
}
.assessment-text-bold-13 {
  font-family: assessment-Bold !important;
  font-size: calc(0.65rem + 0.2vw) !important;
  line-height: calc(0.9rem + 0.2vw) !important;
}
.assessment-text-semibold-13 {
  font-family: assessment-SemiBold !important;
  font-size: calc(0.65rem + 0.2vw);
  line-height: calc(0.9rem + 0.2vw) !important;
}
.assessment-text-medium-13 {
  font-family: assessment-Medium !important;
  font-size: calc(0.65rem + 0.2vw);
  line-height: calc(0.9rem + 0.2vw) !important;
}
.assessment-text-light-13 {
  font-family: assessment-Light !important;
  font-size: calc(0.65rem + 0.2vw);
  line-height: calc(0.9rem + 0.2vw) !important;
}
.assessment-text-light-14 {
  font-family: assessment-Light !important;
  font-size: calc(0.635rem + 0.2vw);
  line-height: calc(1rem + 0.2vw);
}
.assessment-text-regular-14 {
  font-family: assessment-Regular !important;
  font-size: calc(0.635rem + 0.2vw);
  line-height: calc(1rem + 0.2vw);
}
.assessment-text-bold-14 {
  font-family: assessment-Bold !important;
  font-size: calc(0.635rem + 0.2vw);
  line-height: calc(1.2rem + 0.2vw);
}
.assessment-text-medium-14 {
  font-family: assessment-Medium !important;
  font-size: calc(0.635rem + 0.2vw);
  line-height: calc(1.2rem + 0.2vw);
}
.assessment-text-medium-upper-14 {
  font-family: assessment-Medium;
  font-size: calc(0.635rem + 0.2vw);
  line-height: calc(1.2rem + 0.2vw);
  text-transform: uppercase;
}
.assessment-text-semibold-14 {
  font-family: assessment-SemiBold !important;
  font-size: calc(0.635rem + 0.2vw);
  line-height: calc(1rem + 0.2vw);
}
.assessment-text-bold-46 {
  font-family: assessment-Bold;
  font-size: calc(2rem + 0.2vw);
  line-height: calc(2.8rem + 0.2vw);
}
.assessment-text-bold-47 {
  font-family: assessment-Bold;
  font-size: calc(2.2rem + 0.2vw);
  line-height: calc(2.8rem + 0.2vw);
}
.assessment-text-bold-49 {
  font-family: assessment-Bold;
  font-size: calc(2.8rem + 0.2vw);
  line-height: calc(3.3rem + 0.2vw);
}
.assessment-text-bold-42 {
  font-family: assessment-Bold;
  font-size: calc(2.625rem + 0.2vw);
  line-height: calc(3.3rem + 0.2vw);
}
.assessment-text-semibold-10 {
  font-family: assessment-SemiBold;
  font-size: calc(0.55rem + 0.1vw);
  line-height: calc(1rem + 0.1vw);
}
.assessment-text-medium-10 {
  font-family: assessment-Medium;
  font-size: calc(0.55rem + 0.1vw);
  line-height: calc(1rem + 0.1vw);
}
.assessment-text-medium-10-uppercase {
  font-family: assessment-Medium;
  font-size: calc(0.55rem + 0.1vw);
  line-height: calc(1rem + 0.1vw);
  text-transform: uppercase;
}
.assessment-text-regular-15 {
  font-family: assessment-Regular;
  font-size: calc(0.85rem + 0.1vw);
  line-height: calc(1.25rem + 0.1vw);
}
.assessment-text-medium-15 {
  font-family: assessment-Medium;
  font-size: calc(0.85rem + 0.1vw);
  line-height: calc(1.25rem + 0.1vw);
}
.assessment-text-medium-19 {
  font-family: assessment-Medium;
  font-size: calc(1.1875rem + 0.1vw);
  line-height: calc(1.1875rem + 0.1vw);
}
.assessment-text-semibold-15 {
  font-family: assessment-SemiBold;
  font-size: calc(0.85rem + 0.1vw);
  line-height: calc(1.25rem + 0.1vw);
}
.assessment-text-bold-15 {
  font-family: assessment-Bold;
  font-size: calc(0.85rem + 0.1vw);
  line-height: calc(1.25rem + 0.1vw);
}
.assessment-text-light-15 {
  font-family: assessment-Light;
  font-size: calc(0.85rem + 0.1vw);
  line-height: calc(1.25rem + 0.1vw);
}
.assessment-text-regular-16 {
  font-family: assessment-Regular !important;
  font-size: calc(0.77rem + 0.2vw);
  line-height: calc(1.25rem + 0.2vw);
}
.assessment-text-light-16 {
  font-family: assessment-Light;
  font-size: calc(0.77rem + 0.2vw);
  line-height: calc(1.25rem + 0.2vw);
}
.assessment-text-semibold-16 {
  font-family: assessment-SemiBold;
  font-size: calc(0.77rem + 0.2vw);
  line-height: calc(1.25rem + 0.2vw);
}
.assessment-text-bold-16 {
  font-family: assessment-Bold !important;
  font-size: calc(0.77rem + 0.2vw);
  line-height: calc(1.25rem + 0.2vw);
}
.assessment-text-medium-16 {
  font-family: assessment-Medium !important;
  font-size: calc(0.77rem + 0.2vw);
  line-height: calc(1.25rem + 0.2vw);
}
.assessment-text-light-17 {
  font-family: assessment-Light;
  font-size: calc(1rem + 0.1vw);
  line-height: calc(1.75rem + 0.1vw);
}
.assessment-text-regular-17 {
  font-family: assessment-Regular;
  font-size: calc(1rem + 0.1vw);
  line-height: calc(1.75rem + 0.1vw);
}
.assessment-text-regular-50 {
  font-family: assessment-Regular;
  font-size: 50px;
}
.assessment-text-medium-17 {
  font-family: assessment-Medium;
  font-size: calc(1rem + 0.1vw);
  line-height: calc(1.75rem + 0.1vw);
}
.assessment-text-semibold-17 {
  font-family: assessment-SemiBold;
  font-size: calc(1rem + 0.1vw);
  line-height: calc(1.75rem + 0.1vw);
}
.assessment-text-bold-17 {
  font-family: assessment-Bold;
  font-size: calc(1rem + 0.1vw);
  line-height: calc(1.4rem + 0.1vw);
}
.assessment-text-light-20 {
  font-family: assessment-Light;
  font-size: calc(1rem + 0.3vw);
  line-height: calc(1.2rem + 0.3vw);
}
.assessment-text-regular-20 {
  font-family: assessment-Regular;
  font-size: calc(1rem + 0.3vw);
  line-height: calc(1.2rem + 0.3vw);
}
.assessment-text-bold-20 {
  font-family: assessment-Bold !important;
  font-size: calc(0.85rem + 0.3vw);
  line-height: calc(1.25rem + 0.3vw);
}
.assessment-text-semibold-20 {
  font-family: assessment-SemiBold;
  font-size: calc(1rem + 0.3vw);
  line-height: calc(1.2rem + 0.3vw);
}

.assessment-text-medium-20 {
  font-family: assessment-Medium;
  font-size: calc(1rem + 0.3vw);
  line-height: calc(1.2rem + 0.3vw);
}
// 18px ? 14px ? 13px ? 12px
.assessment-text-medium-18 {
  font-family: assessment-Medium;
  font-size: calc(1rem + 0.125vw);
  line-height: calc(1.5rem + 0.1vw);
}
.assessment-text-regular-18 {
  font-family: assessment-Regular;
  font-size: calc(1rem + 0.125vw);
  line-height: calc(1.5rem + 0.1vw);
}
.assessment-text-bold-18 {
  font-family: assessment-Bold;
  font-size: calc(1rem + 0.125vw);
  line-height: calc(1.5rem + 0.1vw);
}
.assessment-text-semibold-18 {
  font-family: assessment-SemiBold;
  font-size: calc(1rem + 0.125vw);
  line-height: calc(1.5rem + 0.1vw);
}
.assessment-text-light-18 {
  font-family: assessment-Light;
  font-size: calc(1rem + 0.125vw);
  line-height: calc(1.5rem + 0.1vw);
}

.assessment-text-medium-upper-10 {
  font-family: assessment-Medium;
  font-size: calc(0.55rem + 0.1vw);
  line-height: calc(0.9rem + 0.1vw);
  text-transform: uppercase;
}
.assessment-text-medium-48 {
  font-family: assessment-Medium;
  font-size: calc(2.25rem + 0.5vw);
  line-height: calc(3.25rem + 0.5vw);
}
.assessment-text-bold-48 {
  font-family: assessment-Bold;
  font-size: calc(2.25rem + 0.5vw);
  line-height: calc(3rem + 0.5vw);
}
.assessment-text-Semibold-48 {
  font-family: assessment-SemiBold;
  font-size: calc(2.25rem + 0.5vw);
  line-height: calc(3rem + 0.5vw);
}
.assessment-text-Regular-48 {
  font-family: assessment-Regular;
  font-size: calc(2.25rem + 0.5vw);
  line-height: calc(3rem + 0.5vw);
}
.assessment-text-bold-34 {
  font-family: assessment-Bold;
  font-size: calc(2rem + 0.15vw);
  line-height: calc(2.5rem + 0.15vw);
}
.assessment-text-medium-32 {
  font-family: assessment-Medium;
  font-size: calc(1.3rem + 0.75vw);
  line-height: calc(1.625rem + 0.2vw);
}
.assessment-text-medium-34 {
  font-family: assessment-Medium;
  font-size: calc(2rem + 0.15vw);
  line-height: calc(2.5rem + 0.15vw);
}
.assessment-text-medium-36 {
  font-family: assessment-Medium;
  font-size: 2.25rem;
  line-height: calc(2.5rem + 0.15vw);
}

.assessment-text-bold-32 {
  font-family: assessment-Bold;
  font-size: calc(1.3rem + 0.75vw);
  line-height: calc(1.625rem + 0.2vw);
}
.assessment-text-bold-33 {
  font-family: assessment-Bold;
  font-size: calc(2rem + 0.1vw);
  line-height: calc(2rem + 0.1vw);
}
// bold-700
// semibold-600
// medium-500
// regular-400
.assessment-text-light-21 {
  font-family: assessment-Light;
  font-size: calc(1rem + 0.35vw);
}
.assessment-text-bold-21 {
  font-family: assessment-Bold;
  font-size: calc(1rem + 0.35vw);
  line-height: calc(1.1rem + 0.35vw);
}
.assessment-text-regular-21 {
  font-family: assessment-Regular;
  font-size: calc(1rem + 0.35vw);
  line-height: calc(1.1rem + 0.35vw);
  letter-spacing: 1;
}
.assessment-text-semibold-21 {
  font-family: assessment-SemiBold;
  line-height: calc(1.1rem + 0.35vw);
  font-size: calc(1rem + 0.35vw);
}
.assessment-text-medium-21 {
  font-family: assessment-Medium;
  line-height: calc(1.1rem + 0.35vw);
  font-size: calc(1rem + 0.35vw);
}
.assessment-text-light-22 {
  font-family: assessment-Light;
  font-size: calc(1rem + 0.4vw);
  line-height: calc(1.1rem + 0.4vw);
}
.assessment-text-bold-22 {
  font-family: assessment-Bold;
  font-size: calc(1rem + 0.4vw);
  line-height: calc(1.1rem + 0.4vw);
}
.assessment-text-regular-22 {
  font-family: assessment-Regular;
  font-size: calc(1rem + 0.4vw);
  line-height: calc(1.1rem + 0.4vw);
}
.assessment-text-semibold-22 {
  font-family: assessment-SemiBold;
  font-size: calc(1rem + 0.4vw);
  line-height: calc(1.1rem + 0.4vw);
}

.assessment-text-medium-22 {
  font-family: assessment-Medium;
  font-size: calc(1rem + 0.4vw);
  line-height: calc(1.1rem + 0.4vw);
}

.assessment-text-light-23 {
  font-family: assessment-Light;
  font-size: calc(1rem + 0.45vw);
  line-height: calc(1.1rem + 0.45vw);
}
.assessment-text-bold-23 {
  font-family: assessment-Bold;
  font-size: calc(1rem + 0.45vw);
  line-height: calc(1.1rem + 0.45vw);
}
.assessment-text-regular-23 {
  font-family: assessment-Regular;
  font-size: calc(1rem + 0.45vw);
  line-height: calc(1.1rem + 0.45vw);
}
.assessment-text-medium-23 {
  font-family: assessment-Medium;
  font-size: calc(1rem + 0.45vw);
  line-height: calc(1.1rem + 0.45vw);
}
.assessment-text-semibold-23 {
  font-family: assessment-SemiBold;
  font-size: calc(1rem + 0.45vw);
  line-height: calc(1.1rem + 0.45vw);
}

.assessment-text-light-24 {
  font-family: assessment-Light;
  font-size: calc(0.8rem + 0.75vw);
  line-height: calc(0.9rem + 0.75vw);
}

.assessment-text-bold-24 {
  font-family: assessment-Bold;
  font-size: calc(0.8rem + 0.75vw);
  line-height: calc(0.9rem + 0.75vw);
}

.assessment-text-regular-24 {
  font-family: assessment-Regular;
  font-size: calc(0.8rem + 0.75vw);
  line-height: calc(0.9rem + 0.75vw);
}

.assessment-text-semibold-24 {
  font-family: assessment-SemiBold;
  font-size: calc(0.8rem + 0.75vw);
  line-height: calc(0.9rem + 0.75vw);
}

.assessment-text-medium-24 {
  font-family: assessment-Medium;
  font-size: calc(0.8rem + 0.75vw);
  line-height: calc(0.9rem + 0.75vw);
}

.assessment-text-light-25 {
  font-family: assessment-Light;
  font-size: calc(1.5rem + 0.1vw);
  line-height: calc(1.7rem + 0.1vw);
}

.assessment-text-regular-25 {
  font-family: assessment-Regular;
  font-size: calc(1.5rem + 0.1vw);
  line-height: calc(1.7rem + 0.1vw);
}

.assessment-text-medium-25 {
  font-family: assessment-Medium;
  font-size: calc(1.5rem + 0.1vw);
  line-height: calc(1.7rem + 0.1vw);
}

.assessment-text-bold-25 {
  font-family: assessment-Bold;
  font-size: calc(1.5rem + 0.1vw);
  line-height: calc(1.7rem + 0.1vw);
}

.assessment-text-semibold-25 {
  font-family: assessment-SemiBold;
  font-size: calc(1.5rem + 0.1vw);
  line-height: calc(1.7rem + 0.1vw);
}

.assessment-text-light-26 {
  font-family: assessment-Light;
  font-size: calc(1.5rem + 0.1vw);
  line-height: calc(1.8rem + 0.1vw);
}

.assessment-text-regular-26 {
  font-family: assessment-Regular;
  font-size: calc(1.5rem + 0.1vw);
  line-height: calc(1.8rem + 0.1vw);
}

.assessment-text-medium-26 {
  font-family: assessment-Medium;
  font-size: calc(1.5rem + 0.1vw);
  line-height: calc(1.8rem + 0.1vw);
}

.assessment-text-bold-26 {
  font-family: assessment-Bold;
  font-size: calc(1.5rem + 0.1vw);
  line-height: calc(1.8rem + 0.1vw);
}

.assessment-text-semibold-26 {
  font-family: assessment-SemiBold;
  font-size: calc(1.55rem + 0.1vw);
  line-height: calc(1.8rem + 0.1vw);
}

.assessment-text-light-27 {
  font-family: assessment-Light;
  font-size: calc(1.5rem + 0.2vw);
  line-height: calc(1.875rem + 0.2vw);
}

.assessment-text-regular-27 {
  font-family: assessment-Regular;
  font-size: calc(1.5rem + 0.2vw);
  line-height: calc(1.875rem + 0.2vw);
}

.assessment-text-medium-27 {
  font-family: assessment-Medium;
  font-size: calc(1.5rem + 0.2vw);
  line-height: calc(1.875rem + 0.2vw);
}

.assessment-text-bold-27 {
  font-family: assessment-Bold;
  font-size: calc(1.5rem + 0.2vw);
  line-height: calc(1.875rem + 0.2vw);
}

.assessment-text-semibold-27 {
  font-family: assessment-SemiBold;
  font-size: calc(1.5rem + 0.2vw);
  line-height: calc(1.875rem + 0.2vw);
  letter-spacing: 1;
}

.assessment-text-light-28 {
  font-family: assessment-Light;
  font-size: calc(1.55rem + 0.2vw);
  line-height: calc(2rem + 0.2vw);
}

.assessment-text-regular-28 {
  font-family: assessment-Regular;
  font-size: calc(1.55rem + 0.2vw);
  line-height: calc(2rem + 0.2vw);
}

.assessment-text-medium-28 {
  font-family: assessment-Medium;
  font-size: calc(1.55rem + 0.2vw);
  line-height: calc(2rem + 0.2vw);
}

.assessment-text-semibold-title-28 {
  font-family: assessment-Title-SemiBold;
  font-size: calc(1.55rem + 0.2vw);
  line-height: calc(2rem + 0.2vw);
}

.assessment-text-semibold-28 {
  font-family: assessment-SemiBold;
  font-size: calc(1.55rem + 0.2vw);
  line-height: calc(2rem + 0.2vw);
  letter-spacing: 1;
}

.assessment-text-bold-28 {
  font-family: assessment-Bold;
  font-size: calc(1.55rem + 0.2vw);
  line-height: calc(2rem + 0.2vw);
}

.assessment-text-light-29 {
  font-family: assessment-Light;
  font-size: calc(1.6rem + 0.2vw);
  line-height: calc(1.625rem + 0.2vw);
}

.assessment-text-regular-29 {
  font-family: assessment-Regular;
  font-size: calc(1.6rem + 0.2vw);
  line-height: calc(1.625rem + 0.2vw);
}

.assessment-text-medium-29 {
  font-family: assessment-Medium;
  font-size: calc(1.6rem + 0.2vw);
  line-height: calc(1.625rem + 0.2vw);
}

.assessment-text-semibold-29 {
  font-family: assessment-SemiBold;
  font-size: calc(1.6rem + 0.2vw);
  letter-spacing: 1;
  line-height: calc(1.625rem + 0.2vw);
}

.assessment-text-bold-29 {
  font-family: assessment-Bold;
  font-size: calc(1.6rem + 0.2vw);
  line-height: calc(1.625rem + 0.2vw);
}

.assessment-text-light-30 {
  font-family: assessment-Light;
  font-size: calc(1.5rem + 0.4vw);
}

.assessment-text-regular-30 {
  font-family: assessment-Regular;
  font-size: calc(1.5rem + 0.4vw);
}
.assessment-text-regular-32 {
  font-family: assessment-Regular;
  font-size: calc(1.3rem + 0.75vw);
  line-height: 1.5rem;
}
.assessment-text-medium-30 {
  font-family: assessment-Medium;
  font-size: calc(1.5rem + 0.4vw);
  line-height: calc(1.5rem + 0.4vw);
}

.assessment-text-medium-31 {
  font-family: assessment-Medium;
  font-size: calc(0.75rem + 0.1vw);
  line-height: calc(1.5rem + 0.1vw);
}

.assessment-text-bold-30 {
  font-family: assessment-Bold;
  font-size: calc(1.5rem + 0.4vw);
  line-height: calc(1.5rem + 0.4vw);
}

.assessment-text-semibold-30 {
  font-family: assessment-SemiBold;
  font-size: calc(1.5rem + 0.4vw);
  line-height: calc(1.5rem + 0.4vw);
  letter-spacing: 0.5;
}
.assessment-text-semibold-32 {
  font-family: assessment-SemiBold;
  font-size: calc(1.3rem + 0.75vw);
  line-height: calc(1.625rem + 0.2vw);
}
.assessment-text-bold-31 {
  font-family: assessment-Bold;
  font-size: calc(1.55rem + 0.4vw);
  line-height: calc(1.6rem + 0.4vw);
}

.assessment-text-bold-35 {
  font-family: assessment-Bold;
  font-size: calc(2rem + 0.2vw);
  line-height: calc(2rem + 0.3vw);
}

.assessment-text-bold-36 {
  font-family: assessment-Bold;
  font-size: calc(2rem + 0.25vw);
  line-height: calc(2.5rem + 0.3vw);
}

.assessment-text-extra-bold {
  font-family: assessment-ExtraBold;
}

.assessment-h1 {
  font-size: clamp(48px, 3rem, 50px);
  line-height: 4rem;
  font-family: assessment-Bold;
}

.assessment-h2 {
  font-size: clamp(34px, 2.1rem, 36px);
  line-height: calc(2.875rem + 0.2vw);
  font-family: assessment-Bold;
}
// H3 Changemaker 24px
.assessment-h3 {
  font-size: calc(0.8rem + 0.75vw);
  line-height: 2.188rem;
  font-family: assessment-Medium;
}

.assessment-h3-bold {
  font-size: calc(0.8rem + 0.75vw);
  line-height: 2.188rem;
  font-family: assessment-Bold;
}
// H4 Changemaker 20px
.assessment-h4 {
  font-size: calc(1rem + 0.3vw);
  line-height: 1.75rem;
  font-family: assessment-Bold;
}
// H5 Changemaker 18px
.assessment-h5 {
  font-size: 1.125;
  line-height: 1.625rem;
  font-family: assessment-Medium;
}
// H6 Changemaker 16px
.assessment-h6 {
  font-size: calc(0.77rem + 0.25vw);
  line-height: 1.625rem;
  font-family: assessment-Medium;
}
.assessment-h6-bold {
  font-size: calc(0.77rem + 0.25vw);
  line-height: 1.625rem;
  font-family: assessment-Bold;
}
// H7 Changemaker 14px -> H7=H8
.assessment-h7 {
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-family: assessment-Medium;
}
.assessment-h8 {
  font-size: 0.813rem;
  line-height: 1.313rem;
  font-family: assessment-Medium;
}
.assessment-p {
  font-size: calc(0.77rem + 0.25vw);
  line-height: calc(1.25rem + 0.2vw);
  font-family: assessment-Regular;
}

// SH1 Changemaker 16px
.assessment-sh1 {
  font-size: calc(0.77rem + 0.25vw);
  line-height: 1.563rem;
  font-family: assessment-Medium;
}

// SH2 Changemaker 14px
.assessment-sh2 {
  font-size: 0.875rem;
  line-height: 1.313rem;
  font-family: assessment-Medium;
}

// Table Fields - 13px
.assessment-table-fields {
  font-size: calc(0.65rem + 0.2vw);
  line-height: 1.25rem;
  font-family: assessment-Regular;
}

// 12px
.assessment-caption {
  font-family: assessment-Regular;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin: 0%;
}

// 12px
.assessment-caption-uppercase {
  font-family: assessment-Medium;
  font-size: 0.75rem;
  line-height: 0.75rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

// body1 Popppins 16px
.assessment-body1 {
  font-family: assessment-Regular;
  font-size: 1rem;
  line-height: 1rem;
}

// body2 Popppins 14px
.assessment-body2 {
  font-family: assessment-Regular;
  font-size: 0.875rem;
  line-height: 0.875rem;
}
.assessment-body3 {
  font-family: assessment-Regular;
  font-size: 0.75rem;
  line-height: 0.75rem;
}
.assessment-body4 {
  font-family: assessment-Regular;
  font-size: 0.625rem;
  line-height: 0.625rem;
}

// overline Changemaker
.assessment-overline {
  font-family: assessment-Medium;
  font-size: calc(0.55rem + 0.1vw);
  line-height: calc(0.875rem + 0.2vw);
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media (min-height: 100vw) and (orientation: portrait) {
  /* The height is greater than the width */
  .assessment-text-bold-48 {
    font-size: calc(2rem + 0.5vh);
    line-height: calc(2.3rem + 0.5vh);
  }
  .assessment-text-Semibold-48 {
    font-family: assessment-SemiBold;
    font-size: calc(2rem + 0.5vh);
  }
  .assessment-text-Regular-48 {
    font-family: assessment-Regular;
    line-height: calc(2.3rem + 0.5vh);
  }

  .assessment-text-bold-32 {
    font-size: calc(1.3rem + 1vh);
    line-height: calc(1.5rem + 1vh);
  }

  .assessment-text-bold-17,
  .assessment-text-regular-17,
  .assessment-text-semibold-17,
  .assessment-text-medium-17,
  .assessment-text-light-17 {
    font-size: calc(1rem + 0.1vh);
    line-height: calc(1.2rem + 0.1vh);
  }

  .assessment-text-regular-25,
  .assessment-text-medium-25,
  .assessment-text-bold-25,
  .assessment-text-semibold-25,
  .assessment-text-light-25 {
    font-size: calc(1.5rem + 0.1vw);
    line-height: calc(1.7rem + 0.1vh);
  }

  .assessment-text-medium-24 {
    line-height: 2.188rem;
  }

  .assessment-text-medium-24,
  .assessment-text-regular-24,
  .assessment-text-semibold-24,
  .assessment-text-bold-24,
  .assessment-text-light-24 {
    font-size: calc(1.3rem + 0.5vh);
    line-height: calc(1.6rem + 0.5vh);
  }

  .assessment-text-bold-22 {
    font-size: calc(1.2rem + 0.4vw);
    line-height: calc(1.4rem + 0.4vw);
  }

  .assessment-text-medium-18,
  .assessment-text-regular-18,
  .assessment-text-bold-18,
  .assessment-text-semibold-18,
  .assessment-text-light-18 {
    font-size: calc(1.1rem + 0.1vh);
    line-height: calc(1.4rem + 0.1vh);
  }

  .assessment-text-light-16,
  .assessment-text-medium-16,
  .assessment-text-regular-16,
  .assessment-text-semibold-16,
  .assessment-text-bold-16 {
    font-size: calc(0.9rem + 0.3vh);
    line-height: calc(1.2rem + 0.3vh);
  }

  .assessment-text-regular-15,
  .assessment-text-medium-15,
  .assessment-text-semibold-15,
  .assessment-text-bold-15,
  .assessment-text-light-15 {
    font-size: calc(0.85rem + 0.2vh);
    line-height: calc(1.15rem + 0.2vh);
  }

  .assessment-text-medium-12,
  .assessment-text-regular-12,
  .assessment-text-semibold-12,
  .assessment-text-bold-12,
  .assessment-text-medium-upper-12,
  .assessment-text-light-12 {
    font-size: calc(0.6rem + 0.475vh);
    line-height: calc(0.9rem + 0.5vh);
  }

  .assessment-text-medium-14,
  .assessment-text-regular-14,
  .assessment-text-light-14,
  .assessment-text-medium-upper-14,
  .assessment-text-semibold-14,
  .assessment-text-bold-14 {
    font-size: calc(0.78rem + 0.1vh) !important;
    line-height: calc(1.2rem + 0.1vh) !important;
  }

  .assessment-text-regular-13,
  .assessment-text-medium-13,
  .assessment-text-semibold-13,
  .assessment-text-bold-13,
  .assessment-text-light-13 {
    font-size: calc(0.8rem + 0.2vh) !important;
    line-height: calc(1.1rem + 0.2vh) !important;
  }

  .assessment-text-semibold-70 {
    font-size: calc(2.7rem + 0.1vh);
    line-height: calc(3.2rem + 0.1vh);
  }
}
.searchinput {
  border: 1px solid var(--assessment-grey-ninty);
}
.angular-editor-toolbar {
  input[type=color]:not(.color-picker) { /* added :not(.color-picker) so that this will be ignored when the package is updated */
    display: initial !important;
    position: absolute;
    visibility: hidden;
    border: none;
    margin: 0;
    padding: 0;  
    height: 0;
    width: 0;
  }
}
