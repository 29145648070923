@font-face {
  font-family: "assessment-Bold";
  src: url("./fonts/monteserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "assessment-ExtraBold";
  src: url("./fonts/monteserrat/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "assessment-ExtraLight";
  src: url("./fonts/monteserrat/Montserrat-ExtraLight.ttf");
}
@font-face {
  font-family: "assessment-Light";
  src: url("./fonts/monteserrat/Montserrat-Light.ttf");
}
@font-face {
  font-family: "assessment-Medium";
  src: url("./fonts/monteserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "assessment-Regular";
  src: url("./fonts/monteserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "assessment-SemiBold";
  src: url("./fonts/monteserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "assessment-Thin";
  src: url("./fonts/monteserrat/Montserrat-Thin.ttf");
}
@font-face {
  font-family: "assessment-Title-SemiBold";
  src: url("./fonts/halant/Halant-SemiBold.ttf");
}
