/* You can add global styles to this file, and also import other style files */
@import "./assets/custom-themes/default.scss";
@import "./assets/theme/fonts.css";
@import "./assets/theme";
//@import "./assets/custom-themes/default.scss";

html, body { height: 100%; }
body { margin: 0; font-family:assessment-Regular !important; }

@media (min-width: 3800px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 2480px !important;
    }
    html {
        font-size: 20px !important;
    }
}

@media (min-width: 5700px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        zoom: 125% !important;
    }
}
